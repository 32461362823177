import React from "react";
import ReactDOM from "react-dom";
import DefaultAxios from "axios";
import * as serviceWorker from "./serviceWorker";
import { axios, IDynamicObject } from "xa-generics";
import App from "./App";

DefaultAxios.get("/api/rest")
    .then((response) => response.data)
    .then((envKeys: IDynamicObject) => {
        axios.initAxios(envKeys.REST_API_URL);
        ReactDOM.render(<App />, document.getElementById("root"));
    })
    .catch((error) => {});

serviceWorker.unregister();
