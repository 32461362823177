import React from "react";
import PageController from "./components/MainPage/Controller/MainPageController";
import EventController from "./components/ActualEvent/Controller/EventController";
import EventsController from "./components/EventsPage/Controller/EventsController";
import SpecDogController from "./components/SpecDogPage/Controller/SpecDogController";
import { DataContextWrapper } from "./contexts/Data.context";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./Utils/LessAccessories/base.css";

function App() {
    return (
        <DataContextWrapper>
            <div className="App">
                <Router>
                    <Switch>
                        <Route exact path="/spec-dog/:dogID" component={SpecDogController} />
                        <Route exact path="/events" component={EventsController} />
                        <Route exact path="/events/:eventID" component={EventController} />
                        <Route exact path="/" component={PageController} />
                        <Route exact path="*" component={PageController} />
                    </Switch>
                </Router>
            </div>
        </DataContextWrapper>
    );
}

export default App;
