import * as React from "react";
import BackDrop from "./BackDrop";
import { NavLink } from "react-router-dom";
import { DataContext } from "../../contexts/Data.context";
import "./Less/DogBreeds.css";

interface IProps {
    sliding: boolean;
}

const DogBreeds: React.FC<IProps> = (props) => {
    let visibility: string[] = ["sliding-image-montage", "hide-dog-options"];
    let buttonVisibility: string[] = ["close-sliding-montage", "hide-dog-closing-btn"];
    const { showBreeds, setShowBreeds, dogs } = React.useContext(DataContext);
    if (props.sliding) {
        if (showBreeds) {
            visibility = ["sliding-image-montage", "show-dog-options"];
            buttonVisibility = ["close-sliding-montage", "show-dog-closing-btn"];
        }
    }

    return (
        <div>
            {props.sliding && (
                <div>
                    <BackDrop
                        showDogBreeds={showBreeds}
                        closeDogBreeds={() => setShowBreeds(false)}
                    />
                    <button
                        className={buttonVisibility.join(" ")}
                        onClick={() => setShowBreeds(false)}
                    >
                        X
                    </button>
                </div>
            )}
            <div className={props.sliding ? visibility.join(" ") : "image-montage"}>
                {dogs.map((dog) => (
                    <NavLink className="image-card" key={dog.dog_id} to={`/spec-dog/${dog.dog_id}`}>
                        <img src={dog.menu_img} alt={dog.name} className="image-card__dog-image" />
                        <div className="image-card__darker-bg" />
                        <span className="image-card__image-title">{dog.name}</span>
                    </NavLink>
                ))}
            </div>
        </div>
    );
};

export default DogBreeds;
